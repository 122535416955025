import axios from "@/axios.js"

function parsePasswordErrors(passwordError) {
    if (passwordError.includes("This password is too common.")) {
        return "tooCommonPassword"
    } else if (passwordError.includes("The password is too similar to the username.")) {
        return "tooSimilar"
    } else if (passwordError.includes("This password is entirely numeric.")) {
        return "numeric"
    } else if (passwordError.includes("This password is too short.")) {
        return "tooShort"
    }
    return "badPassword"
}

export default {
    login({dispatch}, credentials) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/login/", credentials)
                .then(response => {
                    if (process.env.VUE_APP_USE_TOKEN) {
                        localStorage.setItem("jayauthToken", response.data.token)
                    }
                    dispatch("fetchData")
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            resolve(error)
                        })
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        // checks if the user is active
                        dispatch("is_active", {email: credentials.login})
                        reject("loginError")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    socialLogin({dispatch}, credentials) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/social-login/", credentials)
                .then(response => {
                    if (process.env.VUE_APP_USE_TOKEN) {
                        localStorage.setItem("jayauthToken", response.data.token)
                    }
                    dispatch("fetchData")
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fetchProfile({commit}) {
        return new Promise((resolve, reject) => {
            // TODO create a get all action that will be called every minute for example
            axios.get("/accounts/profile/")
                .then(response => {
                    commit('UPDATE_USER_INFO', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        if (process.env.VUE_APP_USE_TOKEN) {
                            localStorage.removeItem("jayauthToken")
                        }
                        reject("notLogged")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    fetchData({commit, dispatch, rootState}) {
        return new Promise((resolve, reject) => {
            axios.get("/accounts/profile/")
                .then(response => {
                    let user = response.data
                    if (rootState.account.user === null && process.env.VUE_APP_USE_TOKEN) {
                        // first time we check if device exists
                        dispatch("notifications/fetchDevices", null, {root: true})
                    }
                    commit('UPDATE_USER_INFO', user)
                    // Fetch gardens at the same time
                    Promise.all([
                        dispatch("calendar/fetchTasks", null, {root: true}),
                        dispatch("garden/fetchGardens", null, {root: true}),
                        dispatch("garden/fetchVGardens", null, {root: true}),
                        dispatch("localAuthority/fetchMemberOf", null, {root: true})
                    ])
                        // Fetch localAuhtorities infos consequently. fetchSubcription needs gardens
                        .then(() => dispatch("localAuthority/fetchSubscription", null, {root: true})
                            .then(() => resolve(user))
                        )
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        if (process.env.VUE_APP_USE_TOKEN) {
                            localStorage.removeItem("jayauthToken")
                        }
                        reject("notLogged")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    logout({commit}) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/logout/", {revoke_token: true})
                .then(() => {
                    if (process.env.VUE_APP_USE_TOKEN) {
                        localStorage.removeItem("jayauthToken")
                    }
                    commit("UPDATE_USER_INFO", null)
                    resolve()
                })
                .catch(() => {
                    reject("serverError")
                })
        })
    },

    register(context, data) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/register/", data)
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        let errors = {}
                        if (error.response.data.email) {
                            errors.email = "allreadyUsedEmail"
                        }
                        if (error.response.data.password) {
                            errors.password = parsePasswordErrors(error.response.data.password)
                        }
                        if (error.response.data.first_name) {
                            errors.first_name = "badFirstName"
                        }
                        if (error.response.data.last_name) {
                            errors.last_name = "badLastName"
                        }
                        reject(errors)
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    register_confirmation({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/verify-registration/", payload)
                .then((response_verify) => {
                    if (process.env.VUE_APP_USE_TOKEN) {
                        localStorage.setItem("jayauthToken", response_verify.data.token)
                    }
                    dispatch("fetchData")
                        .then((response) => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch((error) => {
                    if (error.response && error.response.status == 400) {
                        reject("invalidSignature")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    deleteAccount({commit}, payload) {
        payload.revoke_token = true
        return new Promise((resolve, reject) => {
            axios.post("/accounts/delete-account/", payload)
                .then(() => {
                    commit("DELETE_ALL_USER_DATA", null, {root: true})
                    resolve()
                })
                .catch((error) => {
                    if (error.response && error.response.status == 400) {
                        reject("authenticationError")
                    }
                    reject("serverError")
                })
        })
    },

    updateProfile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.patch("accounts/profile/", data)
                .then((response) => {
                    commit("UPDATE_USER_INFO", response.data)
                    resolve()
                })
                .catch((error) => {
                    if (error.response && error.response.status == 400) {
                        reject("invalid_name")
                    } else if (error.response && error.response.status == 403 && error.response.data.detail == "Can't have more than 8 gardens") {
                        reject("tooManyGardens")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    send_reset_password({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/send-reset-password-link/", payload)
                .then(() => {
                    dispatch("is_active", {email: payload.login})
                    resolve()
                })
                .catch((error) => {
                    if (error.response && error.response.status == 404) {
                        reject("noUser")
                    } else {
                        reject("serverError")
                    }
                })
        })
    },

    reset_password(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/reset-password/", payload)
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    let errors = {}
                    if (error.response && error.response.status == 404) {
                        errors.user = "noUser"
                    }
                    if (error.response && error.response.status == 400) {
                        if (error.response.data.detail) {
                            if (error.response.data.detail.includes("Invalid signature")) {
                                errors.signature = "invalidSignature"
                            }
                        }
                        if (error.response.data) {
                            errors.password = parsePasswordErrors(error.response.data)
                        }
                    } else {
                        reject('serverError')
                    }
                    reject(errors)
                })
        })
    },

    change_password(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/change-password/", payload)
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    let errors = {}
                    if (error.response && error.response.status == 400) {
                        if (error.response.data) {
                            if (error.response.data.old_password) {
                                errors.old_password = "wrongPassword"
                            }
                            if (error.response.data.password) {
                                errors.password = parsePasswordErrors(error.response.data.password)
                            }
                        }
                    } else {
                        reject("serverError")
                    }
                    reject(errors)
                })
        })
    },

    resend_validation_email({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/resend-email/", payload)
                .then(() => {
                    commit('UPDATE_ACTIVE_STATUS', false)
                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    },

    is_active({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/is-active/", payload)
                .then(response => {
                    commit('UPDATE_ACTIVE_STATUS', !response.data.active)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
