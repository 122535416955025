export default {

    SET_SUBSCRIPTIONS(state, localAuthorities) {
        state.subscriptions = localAuthorities
    },

    SET_MEMBER_OF(state, localAuthority) {
        state.memberOf = localAuthority
    },

    SET_COLOR(state, colors) {
        state.colors = colors
    },

    SET_LOGO_URL(state, url) {
        state.logo_url = url
    },

    SET_TRANSPENT_HEADER(state, transparency) {
        state.transparent_header = transparency
    }
}
