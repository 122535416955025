export default {
    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        state.user = payload;
    },
    UPDATE_LOGIN_STATUS(state, val) {
        state.loginStatus = val;
    },
    UPDATE_REGISTER_STATUS(state, val) {
        state.registerStatus = val;
    },
    UPDATE_ACTIVE_STATUS(state, val) {
        state.userIsInactive = val;
    },
    UPDATE_FIREBASE_TOKEN(state, val) {
        state.firebaseToken.value = val;
    },
    SET_FIREBASE_TOKEN_ID(state, val) {
        state.firebaseToken.id = val;
    }
}
