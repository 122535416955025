export default {

    //simple list of gardens
    gardens: [],

    //for front développment
    vgardens: [],

    fertilizationOptions: [
        {value: null, text: 'Type d\'engrais', disabled: true},
        {value: 'VM', text: 'Du purin'},
        {value: 'AM', text: 'Du fumier'},
        {value: 'CO', text: 'Du compost'},
        {value: 'GF', text: 'Des engrais verts'},
        {value: 'MI', text: 'Des engrais minéraux'},
        {value: 'AF', text: 'Des engrais artificiels'},
        {value: 'OT', text: 'Autre'}
    ],

    mulchOptions: [
        {value: null, text: 'Type de paillage', disabled: true},
        {value: 'MO', text: 'Herbe de tonte'},
        {value: 'GW', text: 'Autres végétaux verts (déchets du jardin)'},
        {value: 'BRF', text: 'BRF'},
        {value: 'ST', text: 'Paille'},
        {value: 'HA', text: 'Foin'},
        {value: 'WD', text: 'Débris de bois (copeaux, écorces, sciures)'},
        {value: 'DL', text: 'Feuilles mortes'},
        {value: 'CB', text: 'Carton'},
        {value: 'TP', text: 'Bâche'},
    ],

    vgardenPlantStates: [
        {value: 'DE', text: 'supprimé'},
        {value: 'SO', text: 'semé'},
        {value: 'AC', text: 'acquis'},
        {value: 'PL', text: 'planté'},
        {value: 'UP', text: 'déplanté'},
        {value: 'PA', text: 'plannifié'},
        {value: 'RE', text: 'retiré'},
    ],

    locationsHelpNeeded: true,
}
