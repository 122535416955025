export default {
  gotQuiz: false,

  establishmentTypes: [
    {
      id: null,
      type: 'SC',
      name: 'Établissements scolaires',
      routeName: 'EtablissementsScolaires',
      end_date: new Date('2022-06-08'),
      typeText: '',
    },
    {
      id: null,
      type: 'YS',
      name: 'Associations et structures jeunesses',
      routeName: 'AssociationsEtStructuresJeunesses',
      end_date: new Date('2022-06-08'),
      typeText: '',
    },
    {
      id: null,
      type: 'ST',
      name: 'Étudiants',
      routeName: 'Etudiants',
      end_date: new Date('2022-09-16 23:59:59+00'),
      typeText: 'les étudiants',
    },
    {
      id: null,
      type: 'SP',
      name: 'Clubs et associations sportives',
      routeName: 'ClubsEtAsociationsSportives',
      end_date: new Date('2022-09-16 23:59:59+00'),
      typeText: 'les clubs de sport et associations sportives',
    },
    {
      id: null,
      type: 'AS',
      name: 'Associations',
      routeName: 'Associations',
      end_date: new Date('2022-09-16 23:59:59+00'),
      typeText: 'les associations',
    },
    {
      id: null,
      type: 'CO',
      name: 'Entreprises',
      routeName: 'Entreprises',
      end_date: new Date('2022-09-16 23:59:59+00'),
      typeText: 'les entreprises',
    },
  ],
  categoriesImages: [
    {
      title: 'PRESENTEZ VOUS !',
      imageName: 'PresentezVous',
    },
    {
      title: 'VOTRE ÉQUIPE !',
      imageName: 'VotreEquipe',
    },
    {
      title: 'LA BIODIVERSITÉ C’EST CARRÉ !',
      imageName: 'LaBiodiversiteCestCarre',
    },
    {
      title: 'LA BIODIVERSITÉ C’EST CARRÉ !',
      imageName: 'LaBiodiversiteCestCarre',
    },
    {
      title: 'CA NOUS NOURRIT !',
      imageName: 'CaNousNourrit',
    },
    {
      title: 'MON BO POTAGER !',
      imageName: 'MonBoPotager',
    },
    {
      title: 'ON EST PRÊTS !',
      imageName: 'OnEstPrets',
    },
    {
      title: 'ENSEMBLE ON VA PLUS LOIN !',
      imageName: 'EnsembleOnVaPlusLoin',
    },
    {
      title: 'J’APPRENDS JE PARTAGE !',
      imageName: 'JApprendsJePartage',
    },
    {
      title: 'J\'APPRENDS, JE PARTAGE !',
      imageName: 'JApprendsJePartage',
    },
    {
      title: 'QUELLE DEBROUILLARDISE !',
      imageName: 'QuelleDebrouillardise',
    },
    {
      title: 'MINI POUSSES !',
      imageName: 'MiniPousses',
    },
    {
      title: 'BONUS BONUS !',
      imageName: 'BonusBonus',
    }
  ],
  cdfAnswers: null
}
