import axios from "@/axios.js"

export default {
    getQuiz(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('/cdf_quizzes/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    retrieveQuiz(context, params) {
        return new Promise((resolve, reject) => {
            params["details"] = true
            axios.get('/cdf_quizzes/' + params.id.toString(), {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getQuizAnswerer(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('/cdf_answers/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createQuizAnswerer(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/cdf_answers/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createQuizResponse(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/cdf_responses/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteQuizResponse(context, params) {
        return new Promise((resolve, reject) => {
            axios.destroy('/cdf_responses/' + params["responseId"].toString() + '/')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateQuizResponse(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch('/cdf_responses/' + payload["responseId"].toString() + '/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
