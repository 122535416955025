import axios from "@/axios.js"

export default {
    addGarden({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/gardens/", payload)
                .then((response) => {
                    commit('ADD_GARDEN', Object.assign(response.data, {id: response.data.id, name: response.data.name}))
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchGardens({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("api/gardens/")
                .then((response) => {
                    commit("SET_GARDENS", response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateGarden({commit,dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("api/gardens/" + payload.id + '/', payload)
                .then((response) => {
                    commit("UPDATE_GARDEN", response.data)
                    dispatch("fetchVGardens")
                        .then(() => resolve())
                })
                .catch(() => {
                    reject()
                })
        })
    },
    addPlant({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("api/gardenPlants/", payload)
                .then((response) => {
                    axios.get("api/gardens/" + payload.garden.toString() + '/')
                        .then((response_get) => {
                            commit('ADD_PLANT_TO_GARDEN', response_get.data)
                            resolve(response)
                        })
                        .catch((error_get) => {
                            reject(error_get)
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removePlant({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("api/gardenPlants/" + payload.id.toString() + '/', {data: {garden: payload.garden}})
                .then((response) => {
                    axios.get("api/gardens/" + payload.garden.toString() + '/')
                        .then((response_get) => {
                            commit('ADD_PLANT_TO_GARDEN', response_get.data)
                            resolve(response)
                        })
                        .catch((error_get) => {
                            reject(error_get)
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addVGarden({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("vgardens/", payload)
                .then(response => {
                    commit("ADD_VGARDEN", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchVGardens({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("vgardens/")
                .then(response => {
                    commit('SET_V_GARDENS', response.data)
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    getVGarden(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get("vgardens/" + payload.index + "/")
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    updateVGarden({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("vgardens/" + payload.id + "/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN', response.data)
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    deleteVGarden(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("vgardens/" + payload + "/", {state: "DE"})
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    addVGardenPlant(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("vgardenplants/", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateVGardenPlant({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("vgardenplants/" + payload.index.toString() + "/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        x: payload.x,
                        y: payload.y,
                        fixed: payload.fixed
                    })
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    getVGPlantsAdvice(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get("vgardens/" + payload.index.toString() + '/advices/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Mulching management
    createMulching({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("mulchings/", payload)
                .then(response => {
                    commit("ADD_MULCHING", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateMulching({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("mulchings/" + payload.mulching_id.toString() + "/", payload)
                .then(response => {
                    commit("UPDATE_MULCHING", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteMulching({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("mulchings/" + payload.mulching_id.toString() + "/")
                .then(response => {
                    commit("ADD_MULCHING", payload)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // fertilization management

    createFertilization({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("fertilizations/", payload)
                .then(response => {
                    commit("ADD_FERTILIZATION", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateFertilization({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("fertilizations/" + payload.fertilization_id.toString() + "/", payload)
                .then(response => {
                    commit("UPDATE_FERTILIZATION", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteFertilization({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("fertilizations/" + payload.fertilization_id.toString() + "/")
                .then(response => {
                    commit("DELETE_MULCHING", payload)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Warning, for now the delete actually deletes the garden plant. In the future, we might want to be careful to edit it and add a status to it
    deleteVGardenPlant({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("vgardenplants/" + payload.index + "/", {data: {vegetable_garden: payload.vgardenId}})
                .then((response) => {
                    commit('REMOVE_VGARDEN_PLANT', {
                        vgardenId: payload.vgardenId,
                        garden_plant: payload.gardenPlantId
                    })
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    createVGPlantState(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("vgplantstates/", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Watering management

    createWatering({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("waterings/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        watering: response.data,
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateWatering({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("waterings/" + payload.index.toString() + "/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        watering: {date: response.data},
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteWatering({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("waterings/" + payload.index.toString() + "/")
                .then(response => {
                    commit('REMOVE_WATERNING', {
                        vg_plant: payload.index_store,
                        id: payload.index
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Harvest management

    createHarvest({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("harvests/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        harvest: response.data,
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateHarvest(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("harvests/" + payload.index.toString() + "/", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteHarvest({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("harvests/" + payload.index.toString() + "/")
                .then(response => {
                    commit('REMOVE_HARVEST', {
                        vg_plant: payload.index_store,
                        id: payload.index
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Illness management

    createIllness({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("illnesses/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        illness: response.data,
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateIllness({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("illnesses/" + payload.index.toString() + "/", payload)
                .then(response => {
                    commit('UPDATE_VGARDEN_PLANT', {
                        vgardenId: payload.vegetable_garden,
                        index: payload.index_store,
                        illness: response.data,
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteIllness({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("illnesses/" + payload.index.toString() + "/")
                .then(response => {
                    commit('REMOVE_ILLNESS', {
                        vgarden: payload.vgardenId,
                        vg_plant: payload.vgPlantId,
                        id: payload.index
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
