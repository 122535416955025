import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'


// Filters
import '@/filters/filters.js'

//JayaToolTip
import JayaTooltip from '@/components/global/JayaTooltip.vue'
//v-viewer
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
//JayaDivider
import JayaDivider from "@/components/global/JayaDivider"
// Use v-calendar & v-date-picker components------------------------------------------------------------
import VCalendar from 'v-calendar';
// VBHover
import {VBHover} from 'bootstrap-vue'

Vue.component(JayaTooltip.name, JayaTooltip)

Vue.use(VueViewer, {
    defaultOptions: {
        zIndex: 60000,
        toolbar: false,
        navbar: false
    }
})

Vue.component(JayaDivider.name, JayaDivider)

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
const redirectManager = {
    install(Vue) {
        Vue.prototype.getUrl = function (url) {
            if (process.env.VUE_APP_USE_TOKEN) {
                window.cordova.InAppBrowser.open(url, '_system')
            } else {
                window.open(url)
            }
        }
    }
}
export default redirectManager
//-------------------------------------------------------------------------------------------------------
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-hover', VBHover)


Vue.component(FeatherIcon.name, FeatherIcon)


