function createTasks(vgarden) {
    let vGardenTasks = []
    for (let j = 0; j < vgarden.vgarden_plants.length; j++) {
        let transplant = false
        let harvest = false
        let sowing = false
        let task_state = null
        let state = vgarden.vgarden_plants[j].current_state
        let plantTypeId = vgarden.vgarden_plants[j].garden_plant.plant.id
        if (vgarden.vgarden_plants[j].garden_plant.plant.jaya_infos) {
            let month = new Date().getMonth() + 1
            if (state == 'PA') {
                for (let transplantMonth of vgarden.vgarden_plants[j].garden_plant.plant.jaya_infos.planting_periods) {
                    if (month >= transplantMonth[0] && month <= transplantMonth[1]) {
                        sowing = true
                        task_state = "sow"
                        break
                    }
                }
            }
            if (state == 'SO' || state == 'AC') {
                for (let transplantMonth of vgarden.vgarden_plants[j].garden_plant.plant.jaya_infos.planting_periods) {
                    if (month >= transplantMonth[0] && month <= transplantMonth[1]) {
                        transplant = true
                        task_state = "transplant"
                        break
                    }
                }
            }
            if (state == 'PL') {
                for (let harvestMonth of vgarden.vgarden_plants[j].garden_plant.plant.jaya_infos.harvest_periods) {
                    if (month >= harvestMonth[0] && month <= harvestMonth[1]) {
                        harvest = true
                        task_state = "harvest"
                        break
                    }
                }
            }
        }
        if (!vGardenTasks.some(task => {
            return task.object === 'plant' && task.plantType.id === plantTypeId && task.tasks[task_state]
        })) {
            vGardenTasks.push({
                object: 'plant',
                plantType: vgarden.vgarden_plants[j].garden_plant.plant,
                tasks: {
                    sow: sowing,
                    transplant: transplant,
                    harvest: harvest
                }
            })
        }
    }
    return vGardenTasks
}

export default {
    ADD_GARDEN(state, garden) {
        state.gardens.push(garden)
    },
    SET_GARDENS(state, gardens) {
        state.gardens = gardens
    },
    UPDATE_GARDEN(state, garden) {
        const gardenIndex = state.gardens.findIndex((e) => e.id == garden.id)
        state.gardens[gardenIndex] = garden
        state.gardens.push([])
        state.gardens.pop()
    },
    ADD_PLANT_TO_GARDEN(state, payload) {
        const gardenIndex = state.gardens.findIndex((e) => e.id == payload.id)
        try {
            state.gardens[gardenIndex].plants = payload.plants
        } catch (e) {
            // For now no plants in the backend garden
            state.gardens[gardenIndex].plants = []
            state.gardens[gardenIndex].plants.push(payload.plantId)
        }
    },
    ADD_VGARDEN(state, vgarden) {
        state.vgardens.push(vgarden)
    },
    UPDATE_VGARDEN_PLANT(state, payload) {
        let VGardenUpdating = state.vgardens.find(vgarden => vgarden.id === payload.vgardenId)
        if (payload.x) {
            VGardenUpdating.vgarden_plants[payload.index].x = payload.x
        }
        if (payload.y) {
            VGardenUpdating.vgarden_plants[payload.index].y = payload.y
        }
        if (payload.fixed != null && payload.fixed != undefined) {
            VGardenUpdating.vgarden_plants[payload.index].fixed = payload.fixed
        }
        if (payload.current_state != null && payload.current_state != undefined) {
            VGardenUpdating.vgarden_plants[payload.index].current_state = payload.current_state
        }
        if (payload.watering) {
            VGardenUpdating.vgarden_plants[payload.index].waterings.push(payload.watering)
        }
        if (payload.harvest) {
            VGardenUpdating.vgarden_plants[payload.index].harvests.push(payload.harvest)
        }
    },


    SET_V_GARDENS(state, vgardens) {
        //Set tasks to each vgarden_plant
        for (let i = 0; i < vgardens.length; i++) {
            vgardens[i].tasks = createTasks(vgardens[i])
        }
        state.vgardens = vgardens
    },

    UPDATE_VGARDEN(state, vgarden) {
        const vgardenIndex = state.vgardens.findIndex((e) => e.id == vgarden.id)
        vgarden.tasks = createTasks(vgarden)
        state.vgardens[vgardenIndex] = vgarden
        state.vgardens.push([])
        state.vgardens.pop()
    },
    REMOVE_VGARDEN_PLANT(state, payload) {
        const vgardenIndex = state.vgardens.findIndex((e) => e.id == payload.vgardenId)
        let vgarden = state.vgardens[vgardenIndex]
        let vgardenPlantId = vgarden.vgarden_plants.findIndex((e) => e.garden_plant.id == payload.garden_plant)
        vgarden.vgarden_plants.splice(vgardenPlantId, 1)
    },
    ADD_MULCHING(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        vgarden.mulchings.push(payload)
    },
    UPDATE_MULCHING(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        let mulchingIndex = vgarden.mulchings.findIndex(mulching => mulching.id === payload.id)
        vgarden.mulchings[mulchingIndex] = payload
    },
    DELETE_MULCHING(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        let mulchingIndex = vgarden.mulchings.findIndex(mulching => mulching.id === payload.mulching_id)
        vgarden.mulchings.splice(mulchingIndex, 1)
    },
    ADD_FERTILIZATION(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        vgarden.fertilizations.push(payload)
    },
    UPDATE_FERTILIZATION(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        let mulchingIndex = vgarden.fertilizations.findIndex(mulching => mulching.id === payload.id)
        vgarden.fertilizations[mulchingIndex] = payload
    },
    DELETE_FERTILIZATION(state, payload) {
        let vgarden = state.vgardens.find(vgarden => vgarden.id === payload.vegetable_garden)
        let mulchingIndex = vgarden.fertilizations.findIndex(mulching => mulching.id === payload.fertilization_id)
        vgarden.fertilizations.splice(mulchingIndex, 1)
    },
    REMOVE_HARVEST(state, payload) {
        let vgarden = state.vgardens.find((e) => {
            return e.id == payload.vgardenId
        })
        let vgardenPlant = vgarden.vgarden_plants.find(plant => {
            return plant.id == payload.vgPlantId
        })
        let harverstIndex = vgardenPlant.harvests.findIndex((e) => e.id == payload.index)
        vgardenPlant.harvest.splice(harverstIndex, 1)
    },
    REMOVE_WATERNING(state, payload) {
        let vgarden = state.vgardens.find((e) => {
            return e.id == payload.vgardenId
        })
        let vgardenPlant = vgarden.vgarden_plants.find(plant => {
            return plant.id == payload.vgPlantId
        })
        let wateringIndex = vgardenPlant.waterings.findIndex((e) => e.id == payload.index)
        vgardenPlant.harvest.splice(wateringIndex, 1)
    },
    REMOVE_ILLNESS(state, payload) {
        let vgarden = state.vgardens.find((e) => {
            return e.id == payload.vgardenId
        })
        let vgardenPlant = vgarden.vgarden_plants.find(plant => {
            return plant.id == payload.vgPlantId
        })
        let illnessIndex = vgardenPlant.illnesses.findIndex((e) => e.id == payload.index)
        vgardenPlant.harvest.splice(illnessIndex, 1)
    },
    UPDATE_LOCATIONS_HELP_NEEDED(state, isNeeded) {
        state.locationsHelpNeeded = isNeeded
    }
}
