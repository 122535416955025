export default {
    SET_UPDATE_BIODIV_SCORE(state, value) {
        state.updateBiodivScore = value
    },
    SET_QUIZZ_CDF(state, value) {
        state.cdfQuiz = value
    },
    SET_ANSWERS_CDF(state, value) {
        state.cdfAnswers = value
    }
}
