export default {

    plants: [],
    filters: {
        category: [],
        shade: [],
        duration: [],
        fertility: [],
        beneficEffect: [],
        concernedSpicie: [],
        flowerColor: []
    },
    // for other filter values
    temperature: 0,
    local: false,
    balcony: false,
    residencePlants: ["Pin parasol", "Chêne pédonculé", "Cerisier doux"],
    animals: [
        {   id: 1,
            commmon_name: "Moineau commun",
            scientific_name: "Passer domesticus",
            type: "oiseaux",
            img: {
                license: "CC-BY-3.0",
                owner: "Kookaburra",
                url: "https://upload.wikimedia.org/wikipedia/commons/d/d9/House_sparrowII.jpg"
            },
            description: "Le Moineau domestique (Passer domesticus) est une espèce de petits passereaux de la famille des Passeridae. C'est un petit oiseau assez trapu, mesurant environ 16 cm de long pour un poids allant de 24 à 39,5 g. Il présente un dimorphisme sexuel, comparable à celui du Moineau rutilant. Les femelles et les jeunes oiseaux sont pâles, colorés de brun et de gris, tandis que les mâles ont le teint plus vif, avec des marques noires, blanches et brunes. Granivore, il se nourrit majoritairement de céréales et d'autres graines, mais se montre opportuniste, consommant divers invertébrés, les insectes en premier lieu, qui servent par ailleurs d'alimentation de base aux oisillons. Ses prédateurs principaux sont les chats domestiques et les rapaces. ",
            observable: true,
            citizen_science_name: "Oiseaux des jardins",
            url_citizen_science: "https://www.oiseauxdesjardins.fr/"
        },
        {   id: 2,
            commmon_name: "Pinson des arbres",
            scientific_name: "Fringilla coelebs",
            type: "oiseaux",
            img: {
                license: "CC BY-SA 2.5",
                owner: "Andreas Trepte",
                url: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Chaffinch_%28Fringilla_coelebs%29.jpg?uselang=fr"
            },
            description: "Le Pinson des arbres (Fringilla coelebs) est une espèce de petits passereaux, partiellement migrateur, très répandu, de la famille des Fringillidés. C'est la plus fréquente et la plus répandue des trois espèces de pinsons. Il est présent dans toute l'Europe à l'exception de l'Islande et de la Scandinavie la plus septentrionale, son aire de répartition s'étend vers l'est jusqu'en Sibérie centrale. C'est aussi un oiseau reproducteur en Afrique du Nord et au Proche-Orient jusqu'en Iran. En Nouvelle-Zélande et en Afrique du Sud, le pinson a été introduit par l'homme. " +
                " " +
                "En Europe centrale, le pinson est l'un des oiseaux reproducteurs les plus répandus. Son aire de répartition s'étend de la côte à la limite des arbres dans les montagnes. Les pinsons d'Europe du Nord et de l'Est sont des oiseaux migrateurs, alors qu'en Europe centrale, ils sont des migrateurs partiels. On distingue plusieurs sous-espèces. Trois d'entre elles se trouvent aux îles Canaries, ainsi qu'une aux Açores, à Madère, en Sardaigne et en Crète1. Comme tous les fringillidés, c'est une espèce protégée en France2. ",
            observable: true,
            citizen_science_name: "Oiseaux des jardins",
            url_citizen_science: "https://www.oiseauxdesjardins.fr/"
        },
        {   id: 3,
            commmon_name: "Mésange bleue",
            scientific_name: "Cyanistes caeruleus",
            type: "oiseaux",
            img: {
                license: "CC BY-SA 4.0",
                owner: "PeterRohrbeck",
                url: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Blaumeise11ib_%28cropped%29.jpg?uselang=fr"
            },
            description: "La Mésange bleue (Cyanistes caeruleus) est une espèce de passereaux de la famille des paridés. " +
                " " +
                "Cette mésange commune est facilement identifiable grâce à sa petite taille, ses fines pattes, son bec fin, et son plumage à dominante de bleu cobalt sur la calotte, les ailes et le dessus de la queue, jaune sur le poitrail et le ventre. " +
                " " +
                "Elle occupe préférentiellement les habitats pourvus de nombreux arbres et de verdure où elle trouve sa nourriture. Les forêts de feuillus et les bocages sont ses milieux de prédilection mais elle fréquente aussi les parcs et les jardins. Espèce européenne, elle partage son habitat avec d'autres espèces de mésanges (comme la mésange charbonnière) qui montrent des différences dans la distribution spatiale et dans la niche écologique exploitée. Oiseau essentiellement insectivore et sédentaire, il est familier aux hommes et fait preuve d'une certaine tendance anthropophile qui s'adapte le plus volontiers aux jardins des localités habitées, même dans les grandes villes. " +
                " " +
                "Contrairement aux effectifs des mésanges spécialisées plus impactés par la fragmentation des habitats, ceux de cette mésange généraliste sont globalement stables, voire en légère progression dans la majorité de l'Europe. " +
                " " +
                "Il découle de ces caractéristiques que cette mésange commune fait partie des espèces les plus emblématiques de l'avifaune européenne. ",
            observable: true,
            citizen_science_name: "Oiseaux des jardins",
            url_citizen_science: "https://www.oiseauxdesjardins.fr/"
        },
        {   id: 4,
            commmon_name: "Lucarne Cerf Volant",
            scientific_name: "Cyanistes caeruleus",
            type: "scarabés",
            img: {
                license: "CC BY-SA 4.0",
                owner: "Didier Descouens",
                url: "https://upload.wikimedia.org/wikipedia/commons/7/79/Cerf-volant_MHNT_male_et_femelle.jpg"
            },
            description: "Lucanus cervus est une espèce de coléoptères de la famille des Lucanidae, sous-famille des Lucaninae, de la tribu des Lucanini et du genre Lucanus, vivant en Europe. Il était couramment appelé Cerf-Volant (pour le mâle) ou Grande Biche (pour la femelle) ; Lucane est la forme française du nom générique Lucanus donné par Giovanni Antonio Scopoli. Dans certaines campagnes françaises (Poitou ou Limousin, par exemple), il est également désigné sous le nom vernaculaire de cornard1,2.",
            observable: true,
            citizen_science_name: "Spipoll",
            url_citizen_science: ""
        },
        {   id: 5,
            commmon_name: "Abeilles charpentières",
            scientific_name: "Xylocopa",
            type: "pollinisateurs",
            img: {
                license: "CC BY-SA 4.0",
                owner: "Laszlo",
                url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Bourdon_noir_sur_glycine.jpg/290px-Bourdon_noir_sur_glycine.jpg"
            },
            description: "Les Xylocopes (Xylocopa) ou Abeilles charpentières forment un genre d'insectes hyménoptères de la famille des Apidés. Toutes les espèces d'abeilles de la tribu Xylocopini sont aujourd'hui classées dans ce genre. Cette abeille est très peu agressive envers les êtres humains.",
            observable: true,
            citizen_science_name: "Spipoll",
            url_citizen_science: "https://www.spipoll.org/",
        }
    ]
}
