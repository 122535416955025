import state from './moduleCFPState.js'
import mutations from './moduleCFPMutations.js'
import actions from './moduleCFPActions.js'
import getters from './moduleCFPGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
