export default {
    SET_CORRECT_QUIZ_INFOS(state, quizzes) {
        for (const quiz of quizzes) {
            if(state.establishmentTypes.some(establishment => establishment.type === quiz.type)) {
                let EType = state.establishmentTypes.find(establishment => establishment.type === quiz.type)
                EType.id = quiz.id
                if(quiz.end_date) {
                    EType.end_date = new Date(quiz.end_date)
                }
            }
        }
        state.gotQuiz = true
    },
}
